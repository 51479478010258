.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	display: flex;
	justify-content: center;
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.119);
	width: 90%;
	max-width: 1000px;
}

.close-button {
	background-color: #ff2040;
	color: #ffffff;
	border: none;
	border-radius: 3px;
	width: 30px;
	height: 30px;
}
