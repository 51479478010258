.geracao-leitura-status-alerta-amarela {
  border: #fff solid 1px;
  cursor: pointer;
  animation: animate-amarela 0.8s linear infinite;
}

@keyframes animate-amarela {
  0% {
    box-shadow: #d4a509 0px 0px 15px 5px;
  }
  50% {
    box-shadow: #d4a509 0 0 0;
  }
  100% {
    box-shadow: #d4a509 0px 0px 15px 5px;
  }
}

.geracao-leitura-status-alerta-vermelha {
  border: #fff solid 1px;
  cursor: pointer;
  animation: animate-vermelha 0.8s linear infinite;
}

@keyframes animate-vermelha {
  0% {
    box-shadow: #f00 0px 0px 15px 5px;
  }
  50% {
    box-shadow: #f00 0 0 0;
  }
  100% {
    box-shadow: #f00 0px 0px 15px 5px;
  }
}
