.menu-analise-container {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	padding: 0px;
}

.menu-analise-item,
.menu-analise-item-active,
.menu-analise-item:hover {
	flex-grow: 1;
	width: 400px;
	padding: 5px;
	border-radius: 5px;
	color: #ffffff;
	list-style: none;
	padding-inline: 20px;
	cursor: pointer;
	font-size: clamp(0.5rem, 0.9rem, 0.9rem);
	text-align: center;
}
.menu-analise-item {
	background-color: #ba0000;
}

.menu-analise-item-active {
	background-color: #930000;
}

.menu-analise-item:hover {
	background-color: #cb0b0b;
}
