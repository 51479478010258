.wrapper {
	background-color: #fff !important;
}

.login-box-body {
	background-color: #eee;
}

.login-box button {
	margin-left: 0px;
}

.login-box a:hover {
	cursor: pointer;
}

.animacao-form {
	width: 100%;
	height: 100%;
	animation: animacao 0.4s;
	animation-timing-function: linear;
}

@keyframes animacao {
	from {
		opacity: 0%;
	}
	to {
		opacity: 100%;
	}
}
